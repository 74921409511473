import React from 'react'
import { cssLabelTitle, cssLabelTitleRequired } from "@/components/lp/scalehack/for-sales/company/form"

type Props = {
  label: string
  required: boolean
}

export const FormLabel: React.VFC<Props> = ({ label, required }) => {
  return (
    <span css={required ? cssLabelTitleRequired : cssLabelTitle}>{label}</span>
  )
}