import React from 'react'
import { ErrorMessage } from "@hookform/error-message"
import { cssErrorText, cssLabel } from "@/components/lp/scalehack/for-sales/company/form"
import { FormProps } from '@/typedir/scalehack/for-sales/company/form'
import { css } from '@emotion/react'
import { shs } from '@/components/lp/scalehack/for-sales/theme'
import { FormLabel } from '@/components/lp/scalehack/for-sales/form/formLabel'

export type FormCheckBoxProps = {
  componentName: 'FormCheckBox'
  options: string[]
} & Omit<FormProps, 'placeholder' | 'pattern'>

export const FormCheckBox: React.VFC<FormCheckBoxProps> = ({
  label,
  name,
  required = true,
  register,
  errors,
  options
 }) => {
  const validation = {
    required: required && '1つ以上選択してください。' ,
  }

  return (
    <div css={cssLabel}>
      <FormLabel label={label} required={required} />
      <div css={cssCheckBoxContainer}>
        {options.map((option, i) => (
          <label css={cssCheckBoxItem} key={i}>
            <input
              type="checkbox"
              css={cssCheckBox}
              value={option}
              {...register(name, validation)}
            />
            <span>{option}</span>
          </label>
        ))}
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p css={cssErrorText}>{message}</p>}
      />
    </div>
  )
}

const cssCheckBoxContainer = css`
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;

  ${shs.mq.pc} {
    gap: 5px 12px;
  }
`

const cssCheckBoxItem = css`
  display: flex;
  gap: 0 4px;
  align-items: flex-start;
  font-size: 1.6rem;
  cursor: pointer;
`

const cssCheckBox = css`
  position: relative;
  top: 0.3em;
  display: block;
  flex-shrink: 0;
`