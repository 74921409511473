import React from 'react'
import { css } from '@emotion/react'
import { mq } from '@/components/media/media'
import { Form } from '@/components/lp/scalehack/for-sales/company/form'
import { shs } from '@/components/lp/scalehack/for-sales/theme'

export const Contact: React.VFC = () => {
  return (
    <main>
      <section css={section}>
        <h1 css={title}>
          <span css={jpTitle}>無料登録</span>
          <span css={enTitle}>CONTACT</span>
        </h1>
        <div css={cssNote}>※同業やご記載内容が正確ではないと弊社が判断した場合、ご遠慮させていただいております。</div>
        <Form />
      </section>
    </main>
  )
}

const section = css`
  width: 100%;
  max-width: 632px;
  padding: 96px 16px 48px;
  margin: 0 auto;

  ${mq.pc} {
    padding: 172px 16px 120px;
  }
`

const title = css`
  padding-bottom: 30px;
  text-align: center;

  ${mq.pc} {
    padding-bottom: 46px;
  }
`

const jpTitle = css`
  display: block;
  padding-bottom: 21px;
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 2.2px;
`

const enTitle = css`
  display: block;
  font-family: ${shs.font.helvetica.family};
  font-size: 1.5rem;
  font-weight: 700;
  color: ${shs.color.primaryBlue};
  letter-spacing: 1.5px;
`

const cssNote = css`
  padding-left: 1em;
  margin-bottom: 38px;
  font-size: 1.5rem;
  line-height: 1.6;
  text-indent: -1em;

  ${shs.mq.pc} {
    margin-bottom: 44px;
  }
`
