import React from 'react'
import { ErrorMessage } from "@hookform/error-message"
import { cssErrorText, cssLabel } from "@/components/lp/scalehack/for-sales/company/form"
import { FormProps } from '@/typedir/scalehack/for-sales/company/form'
import { css } from '@emotion/react'
import color from '@/constants/color'
import { FormLabel } from '@/components/lp/scalehack/for-sales/form/formLabel'

export type FormInputProps = {
  componentName: 'FormInput'
  /** input type="text | tel | email | number | url" */
  type?: 'text' | 'tel' | 'email' | 'number' | 'url'
} & FormProps

export const FormInput: React.VFC<FormInputProps> = ({
  label,
  name,
  required = true,
  register,
  errors,
  pattern,
  placeholder,
  type = 'text'
 }) => {
  const validation = {
    required: required && '必須項目に入力してください。' ,
    pattern
  }

  return (
    <label css={cssLabel}>
      <FormLabel label={label} required={required} />
      <input
        css={cssInput}
        type={type}
        placeholder={placeholder ? placeholder : ''}
        {...register(name, validation)}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p css={cssErrorText}>{message}</p>}
      />
    </label>
  )
}

const cssInput = css`
  width: 100%;
  padding: 0 12px;
  font-size: 1.6rem;
  line-height: ${48 / 16};
  background-color: ${color.white1};
  border: ${color.gray1} 1px solid;
  border-radius: 5px;
`