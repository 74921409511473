import React from 'react'

import { Head } from '@/components/lp/scalehack/for-sales/head'
import { Global } from '@emotion/react'
import { Layout } from '@/components/lp/scalehack/for-sales/layout'
import { companyNavListData } from '@/constants/scalehack/for-sales/header'
import { Contact } from '@/components/lp/scalehack/for-sales/company/contact/contact'
import { cssGlobalStyeShs } from '@/pages/group/scalehack/for-sales/'
import { companyAnchorNavList } from '@/constants/scalehack/for-sales/footer'

export default () => {
  return (
    <>
      <Head
        title="無料登録 | テックセールス特化型セールスシェアリングプラットフォーム"
        url="/group/scalehack/for-sales/contact"
        script={['https://sdk.form.run/js/v2/formrun.js']}
      />
      <Global styles={cssGlobalStyeShs} />
      <Layout
        navList={companyNavListData}
        footerAnchorNavList={companyAnchorNavList}
      >
        <Contact />
      </Layout>
    </>
  )
}
