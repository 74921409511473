import { FormCheckBoxProps } from "@/components/lp/scalehack/for-sales/form/formCheckBox"
import { FormInputProps } from "@/components/lp/scalehack/for-sales/form/formInput"
import { FormSelectProps } from "@/components/lp/scalehack/for-sales/form/formSelect"
import { FormTextareaProps } from "@/components/lp/scalehack/for-sales/form/formTextarea"
import { CheckContactMotivation, SelectCompanyDiscovery, SelectMonthlyBudget, SelectStartTime } from "@/typedir/scalehack/for-sales/company/form"

export const contactMotivation: CheckContactMotivation = [
  '営業組織を構築したい',
  '新規顧客を獲得したい',
  '既存顧客のクロスセル・アップセルを目指したい',
  'インサイドセールス・フィールドセールスを強化したい',
  'パートナー連携について',
  '自社に合う営業スタイルを構築したい',
  'その他',
]

export const startTime: SelectStartTime = [
  '今すぐ',
  '1ヶ月以内',
  '3ヶ月以内',
  '半年以内',
  '未定',
]

export const monthlyBudget: SelectMonthlyBudget = [
  '10万円未満',
  '10万~50万円未満',
  '50万~100万円未満',
  '100万円以上',
  '未定',
]

export const companyDiscovery: SelectCompanyDiscovery = [
  'webで検索',
  '知人からの紹介',
  '広告を見て',
  'facebookの発信を見て',
  'facebook広告を見て',
  'Scalehackに知り合いがいる',
  'LinkedInを見て',
  'ご案内メールを見て',
  'その他',
]

type UseFormData = 'register' | 'errors'

export type FormDataShsType =
  | Omit<FormInputProps, UseFormData>
  | Omit<FormSelectProps, UseFormData>
  | Omit<FormCheckBoxProps, UseFormData>
  | Omit<FormTextareaProps, UseFormData>

export const FormDataShsCompany: FormDataShsType[] = [
  {
    componentName: 'FormInput',
    type: 'text',
    label: '会社名',
    name: '会社名',
    required: true
  },
  {
    componentName: 'FormInput',
    type: 'url',
    label: '会社URL',
    name: '会社URL',
    required: true
  },
  {
    componentName: 'FormInput',
    type: 'tel',
    label: '電話番号',
    name: '電話番号',
    required: true
  },
  {
    componentName: 'FormInput',
    type: 'email',
    label: 'メールアドレス',
    name: 'メールアドレス',
    required: true,
    placeholder: '※企業用のアドレスをご記載ください',
    pattern: {
      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      message: '入力されたメールアドレスに間違いがあります。'
    }
  },
  {
    componentName: 'FormInput',
    type: 'text',
    label: '氏名',
    name: '氏名',
    required: true
  },
  {
    componentName: 'FormInput',
    type: 'text',
    label: '部署',
    name: '部署',
    required: true
  },
  {
    componentName: 'FormInput',
    type: 'text',
    label: '役職',
    name: '役職',
    required: true
  },
  {
    componentName: 'FormInput',
    type: 'url',
    label: '商材URL',
    name: '商材URL',
    required: false
  },
  {
    componentName: 'FormCheckBox',
    label: 'お問合せの背景（複数選択可）',
    name: 'お問合せの背景[]',
    required: true,
    options: contactMotivation
  },
  {
    componentName: 'FormTextarea',
    label: 'お問合せ内容',
    name: 'お問合せ内容',
    required: false,
  },
  {
    componentName: 'FormSelect',
    label: '開始時期',
    name: '開始時期',
    required: true,
    options: startTime
  },
  {
    componentName: 'FormSelect',
    label: '月間予算',
    name: '月間予算',
    required: true,
    options: monthlyBudget
  },
  {
    componentName: 'FormSelect',
    label: 'Scalehackを知ったきっかけ',
    name: '知ったきっかけ',
    required: false,
    options: companyDiscovery
  },
]