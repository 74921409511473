import React from 'react'
import { ErrorMessage } from "@hookform/error-message"
import { cssErrorText, cssLabel } from "@/components/lp/scalehack/for-sales/company/form"
import { FormProps } from '@/typedir/scalehack/for-sales/company/form'
import { css } from '@emotion/react'
import color from '@/constants/color'
import { shs } from '@/components/lp/scalehack/for-sales/theme'
import { FormLabel } from '@/components/lp/scalehack/for-sales/form/formLabel'

export type FormTextareaProps = {
  componentName: 'FormTextarea'
} & FormProps

export const FormTextarea: React.VFC<FormTextareaProps> = ({
  label,
  name,
  required = true,
  register,
  errors,
  pattern,
  placeholder,
 }) => {
  const validation = {
    required: required && '必須項目に入力してください。' ,
    pattern
  }

  return (
    <label css={cssLabel}>
      <FormLabel label={label} required={required} />
      <textarea
        css={cssTextarea}
        placeholder={placeholder ? placeholder : ''}
        {...register(name, validation)}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p css={cssErrorText}>{message}</p>}
      />
    </label>
  )
}

const cssTextarea = css`
  width: 100%;
  height: 160px;
  padding: 12px;
  font-size: 1.6rem;
  resize: none;
  background-color: ${color.white1};
  border: ${color.gray1} 1px solid;
  border-radius: 5px;

  ${shs.mq.pc} {
    height: 200px;
  }
`