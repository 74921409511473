import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import color from '@/constants/color'
import { useLocation } from '@reach/router'
import arrow from '@/images/arrow_blue.svg'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormData } from '@/typedir/scalehack/for-sales/company/form'
import { FormInput } from '@/components/lp/scalehack/for-sales/form/formInput'
import { FormCheckBox } from '@/components/lp/scalehack/for-sales/form/formCheckBox'
import { FormSelect } from '@/components/lp/scalehack/for-sales/form/formSelect'
import { FormTextarea } from '@/components/lp/scalehack/for-sales/form/formTextarea'
import { FormDataShsCompany, FormDataShsType } from '@/constants/scalehack/for-sales/company/formData'
import { cssFlexCenter, cssFlexJustifyCenter } from '@/styles/general'
import { shs } from '@/components/lp/scalehack/for-sales/theme'

export const Form: React.VFC = () => {
  const pathname = useLocation().pathname
  const query = new URLSearchParams(useLocation().search)

  let utmFrom
  if (pathname.includes('contact')) {
    utmFrom = '企業登録'
  } else if (pathname.includes('apply')) {
    utmFrom = 'メンバー登録'
  }

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<FormData>({
    defaultValues: {
      'utm_source': query.get('utm_source'),
      'utm_campaign': query.get('utm_campaign'),
      'utm_term': query.get('utm_term'),
      'utm_content': query.get('utm_content'),
      'utm_medium': query.get('utm_medium'),
      '種類': utmFrom
    }
  })

  const contactForm = useRef<HTMLFormElement>(null)
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [showMessage, setShowMessage] = useState(false)
  const onSubmit = handleSubmit(() => {
    const recaptchaValue = recaptchaRef.current?.getValue() || ''
    if (!recaptchaValue.length) {
      setShowMessage(true)
      return
    }
    contactForm.current?.submit()
  })

  const [isChecked, setIsChecked] = useState(false)
  const toggleCheckbox = () => {
    setIsChecked(!isChecked)
  }

  return (
    <>
      <form
        noValidate
        onSubmit={onSubmit}
        ref={contactForm}
        action="https://form.run/api/v1/r/uvd5p3w681de0wjkz8hlzhw5"
        method="post"
      >
        {FormDataShsCompany.map((form: FormDataShsType, i) => {
          switch (form.componentName) {
            case 'FormInput':
              return <FormInput key={i} {...form} register={register} errors={errors} />
            case 'FormTextarea':
              return <FormTextarea key={i} {...form} register={register} errors={errors} />
            case 'FormSelect':
              return <FormSelect key={i} {...form} register={register} errors={errors} />
            case 'FormCheckBox':
              return <FormCheckBox key={i} {...form} register={register} errors={errors} />
          }
        })}
        <label css={cssLabelCheckbox}>
          <input
            css={cssCheckTerm}
            type="checkbox"
            onChange={toggleCheckbox}
          />
          <span css={cssLabelTitleCheckbox(isChecked)}>
            株式会社Scalehackの
            <a
              css={cssTextButtonBlank}
              href="/group/scalehack/policy"
              target="_blank"
            >
              プライバシーポリシー
              <span>
                <img src={arrow} alt="外部ページへのリンク" />
              </span>
            </a>
            に同意する
          </span>
        </label>
        <div css={cssFlexJustifyCenter}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lc1WqofAAAAAAPSsvJX6S_0IBR1qqSbAP_7DQV6"
          />
        </div>
        <p css={cssCenterErrorText}>
          {showMessage ? 'あなたがロボットでないことを確認してください。' : ''}
        </p>
        <button css={[cssServiceButton, cssFlexCenter]} type="submit" disabled={!isChecked}>
          送信する
        </button>

        <input type="hidden" {...register('utm_source')} />
        <input type="hidden" {...register('utm_campaign')} />
        <input type="hidden" {...register('utm_term')} />
        <input type="hidden" {...register('utm_content')} />
        <input type="hidden" {...register('utm_medium')} />
        <input type="hidden" {...register('種類')} />
      </form>
    </>
  )
}

// Form
export const cssLabel = css`
  display: block;
  margin-bottom: 22px;
`

const cssLabelCheckbox = css`
  ${cssLabel}

  display: flex;
  justify-content: center;
  margin: 40px 0;
  letter-spacing: 1.6px;
  cursor: pointer;
`

export const cssLabelTitle = css`
  display: block;
  padding-bottom: 11px;
  font-size: 1.6rem;
  color: ${color.black1};

  ${shs.mq.pc} {
    padding-bottom: 15px;
  }
`

const cssLabelTitleCheckbox = (isChecked: boolean) => css`
  ${cssLabelTitle}

  position: relative;
  display: inline;
  padding: 0 0 0 28px;

  ${shs.mq.pc} {
    padding-bottom: 0;
  }

  &::before {
    position: absolute;
    top: 1px;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    content: '';
    background-color: ${color.white1};
    border: ${color.gray4} 1px solid;
    border-radius: 3px;
  }

  ${isChecked && css`
    &::before {
      background: ${color.blue6};
    }

    &::after {
      position: absolute;
      top: 4px;
      left: 7px;
      width: 6px;
      height: 12px;
      content: '';
      border-right: ${color.white1} 2px solid;
      border-bottom: ${color.white1} 2px solid;
      transform: rotate(45deg);
    }
  `}
`

export const cssLabelTitleRequired = css`
  ${cssLabelTitle}

  &::before {
    position: relative;
    color: ${color.red2};
    content: '【必須】';
  }
`

const cssCheckTerm = css`
  display: none;
`

export const cssErrorText = css`
  margin-top: 4px;
  font-size: 1.6rem;
  color: ${color.red2};
`

const cssCenterErrorText = css`
  ${cssErrorText}
  text-align: center;
`

// Button
const cssTextButtonBlank = css`
  position: relative;
  display: inline;
  color: ${color.blue6};
  border-bottom: ${color.blue6} 1px solid;

  span {
    display: inline-block;
    width: 14px;
    height: 14px;
    line-height: 1;
  }
`

const cssServiceButton = css`
  width: 100%;
  max-width: 200px;
  height: 50px;
  margin: 40px auto 0;
  font-size: 18px;
  font-weight: 600;
  color: ${color.black2};
  background-color: ${color.orange4};
  border-radius: 90px;

  &:disabled {
    opacity: 0.7;
  }

  ${shs.mq.pc} {
    max-width: 240px;
    height: 70px;
  }
`
