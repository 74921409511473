import React from 'react'
import { ErrorMessage } from "@hookform/error-message"
import { cssErrorText, cssLabel } from "@/components/lp/scalehack/for-sales/company/form"
import { FormProps } from '@/typedir/scalehack/for-sales/company/form'
import { css } from '@emotion/react'
import color from '@/constants/color'
import iconArrow from '@/images/scalehack/arrow_btm.svg'
import { FormLabel } from '@/components/lp/scalehack/for-sales/form/formLabel'

export type FormSelectProps = {
  componentName: 'FormSelect',
  options: string[]
} & Omit<FormProps, 'placeholder' | 'pattern'>

export const FormSelect: React.VFC<FormSelectProps> = ({
  label,
  name,
  required = true,
  register,
  errors,
  options
 }) => {
  const validation = {
    required: required && '選択してください' ,
  }

  return (
    <label css={cssLabel}>
      <FormLabel label={label} required={required} />
      <select css={cssSelect} {...register(name, validation)}>
        <option value="" hidden>選択してください</option>
        {options.map((option, i)=> (
          <option key={i} value={option}>{option}</option>
        ))}
      </select>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p css={cssErrorText}>{message}</p>}
      />
    </label>
  )
}

const cssSelect = css`
  display: block;
  width: 100%;
  padding: 0 12px;
  font-size: 1.6rem;
  line-height: 48px;
  cursor: pointer;
  background: ${color.white1} url(${iconArrow}) no-repeat right 10px center / 13px;
  border: ${color.gray1} 1px solid;
  border-radius: 5px;
`